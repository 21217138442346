<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <ApeMenu :menus="menus" />
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'Performance',
  components: {
    PageHeaderLayout,
  },
  data() {
    return {
      menus: [
        {
          icon: 'mubiao',
          display_name: '绩效目标一览',
          url: '/performance/objectives',
          permission_name: 'performance_trend_page'
        },
        {
          icon: 'fenpei',
          display_name: '奖金分配明细',
          url: '/performance/bonus',
          permission_name: 'bonus_list'
        },
        {
          icon: 'douyin',
          display_name: '抖音视频案例',
          url: '/performance/case_record',
          permission_name: 'case_record_tiktok'
        },
        {
          icon: 'discovery-index',
          display_name: '总监绩效',
          url: '/performance/dept',
          permission_name: 'perf_dept_list'
        },
      ],
    }
  },
}
</script>

<style scoped></style>
